import React, { useEffect } from 'react';
import { useSharedState } from '../SharedState/PageSharedState';

  const CursorOverContainer = ({ children }) => {
  
    const [state, setState] = useSharedState();
    const overContainer = () => {
      //setState({CursorOverContainer: true, cursorOverContainerClass: "cursorText", cursorOverContainerText: "PLAY" });
    };
    const notOverContainer = () => {
      setState({CursorOverContainer: false, cursorOverContainerClass: "", cursorOverContainerText: "" });
    };

    

      useEffect(() => {

        const container = document.querySelector(".CursorOverContainer");

    container.addEventListener("mouseout", e => {
        // console.log("TIME mouse Out");
        requestAnimationFrame(() => {
          notOverContainer();
        });
    })
    container.addEventListener("mouseover", e => {
      // console.log("TIME mouse Over");
      requestAnimationFrame(() => {
        overContainer();
      });
    })
        return undefined
      }, []);

    return (
        <div className="CursorOverContainer">
        {children}
        </div>
    );
  };

  export default CursorOverContainer;